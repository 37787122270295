@use "../../_var.scss" as var;

.header-bar {
    width: "100%";
    box-shadow: var.$box-shadow;
    padding: 21px 40px 21px;
    box-sizing: border-box;
    h1 {
        color: var.$text-primary;
        display: inline;
        font-size: 50px;
        margin-top: -6px;
    
        span {
            color: var.$main-blue;
        }
    }
}



#header-links {
    display: flex;
    width: 100%;
    img{
	   margin-top: 30px;
        height: 40px;
    }

    a {
	   color: var.$text-primary;
        vertical-align: top;
        font-size: 30px;
        line-height: 78px;
        text-decoration: none;
    }

    #header-experience {
        color: var.$main-green;
    }
    #header-about {
	   color: var.$main-blue
    }

    #header-courses {
	   color: var.$main-red
    }
}
