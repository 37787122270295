@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Caladea&display=swap');

body {
    margin: 0;
    font-family: 'Caladea', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}
body, html {
     margin: 0 auto;
     padding: 0;
}
