@use "../_var.scss" as var;

.fadeIn {
   animation: fadeInAnimation ease 3s;
   animation-iteration-count: 1;
   animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
      opacity: 0.9;
  }
}

.fadeInOut {
   animation: fadeInOutAnimation linear 2s;
   animation-iteration-count: 1;
   animation-fill-mode: forwards;
}

@keyframes fadeInOutAnimation {
    0%,100% {
	opacity: 0;
    }
    50% {
	opacity: 1;
    }
}

#welcome {
    color: var.$text-primary;
    font-size: 100px;
    text-shadow: -1px 1px 5px rgba(0, 0, 0, 0.75);
}

#progress-bar {
    background: linear-gradient(360deg, rgba(255,0,0,1) 0%, rgba(10,180,28,1) 78.5398163%, rgba(255,0,0,1) 100%);
    width: 40px;
    height: 400px;
    border-radius: 1rem;
}

.marker {
    width: 40px;
    height: 2px;
    background-color: black;
}
