@use "../../_var.scss" as var;

.pop:hover {
    transform: scale(1.02);
}

.border-link {
    padding: 3px;
    border: 1px solid transparent;
    border-radius: 5px;
}
.border-link:hover {
    border: 1px solid black;
}

.card-container {
    max-width: 500px;
    width: 80%;
    box-sizing: border-box;
    border-radius: var.$bradius;
    box-shadow: var.$box-shadow;
    margin: 20px auto;
    padding: min(30px, 7.5%);
    min-height: 500px;
}

.card-title {
    font-size: 30px;
    justify-content: center;
}

.divider {
    width: 400px;
    height: 2px;
    box-shadow: var.$box-shadow;
    background-color: var.$text-gray;
    float: top;
}

.divider-popup {
    width: 100%;
    height: 2px;
    background-color: var.$text-primary;
    margin-bottom: 10px;
}
.text-holder {
    margin-top: 20px;
    margin-left: 20px;
    font-size: 20px;
}
.course {
    margin-bottom: 10px;
}

.course-listing {
    text-decoration: none;
    color: var.$text-primary;
}

a:hover.course-listing {
    cursor: pointer;
}
a:focus {
    outline:none;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    z-index: 2;
}

.main-pop-up {
    background-color: #99FB89;
    border-radius: 20px;
    border: 3px solid black;
    box-shadow: var.$box-shadow;
}

Popup .a {
    font-size: 20px;
}
